@media only screen and (min-width: 600px) {
  * {
    margin: 0rem;
    padding: 0rem;
    box-sizing: border-box;
  }
  .table_container {
    margin-top: 6.5rem !important;
    overflow: hidden !important;
    background-color: transparent;
    padding: 0.8rem;
    --bs-gutter-x: 0rem !important;
    // margin: 1rem;
    .main_container {
      margin-left: 18rem !important;
    }
    .row_bar {
      margin: 0rem;
      padding: 0rem;

      .setting_heading {
        margin-bottom: 0.5rem !important;
        font-weight: 600;
      }
      .add_product {
        input {
          visibility: hidden;
        }
        label {
          background-color: #ff9900;
          color: white;
          margin-bottom: 1rem;
          width: auto;
          padding-left: 0.8rem;
          padding-top: 0.4rem;
          padding-right: 0.8rem;
          height: 2.5rem;
          border-radius: 0.5rem;
          float: right;
          font-size: 20px;
          font-weight: 600 !important;
          height: 2.5rem !important;
        }
      }
    }
    .table_bg {
      background-color: white !important;
      border-radius: 0.1rem;
      tr {
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
      }
      td {
        border-bottom-width: 0 !important;
        box-shadow: none !important;
      }
    }
    .action_container {
      display: flex;
    }
    .table_heading {
      text-align: center;
      th {
        text-transform: capitalize;
        text-align: center;
      }
    }
    .product_img {
      width: 4rem;
    }
    .edit_btn {
      background-color: #ff9900;
      color: white;
      border: none;
    }
    .delete_btn {
      background-color: transparent !important;
      border: none;
      img {
        width: 1.6rem;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  * {
    margin: 0rem;
    padding: 0rem;
    box-sizing: border-box;
  }
  .table_container {
    margin-top: 6.5rem !important;
    overflow: scroll !important;
    background-color: transparent;
    padding: 0.8rem;
    --bs-gutter-x: 0rem !important;
    // margin: 1rem;
    .main_container {
      margin-left: 8.5rem !important;
    }
    .row_bar {
      margin: 0rem;
      padding: 0rem;
      .setting_heading {
        margin-bottom: 0.5rem !important;
        font-weight: 600;
      }
      .add_product {
        input {
          visibility: hidden;
        }
        label {
          background-color: #ff9900;
          color: white;
          margin-bottom: 0.2rem;
          width: auto;
          padding-left: 0.8rem;
          padding-top: 0.4rem;
          padding-right: 0.8rem;
          height: 2.5rem;
          border-radius: 0.5rem;
          // float: right;
          font-size: 20px;
          font-weight: 600 !important;
          height: 2.5rem !important;
        }
      }
    }
    .table_bg {
      background-color: white !important;
      border-radius: 0.1rem;
    }

    .table_heading {
      text-align: center;
      th {
        text-transform: capitalize;
        text-align: center;
      }
    }
    .product_img {
      width: 4rem;
    }
    .edit_btn {
      background-color: transparent !important;
      border: none;
    }
  }
}

// @media only screen and (min-width: 600px) {
//   .main_setting_container {
//     height: 100vh;

//     h3 {
//       color: rgb(8, 8, 8);
//       margin-left: 23rem;
//       margin-top: 7.5rem;
//       font-weight: 600;
//     }
//     .setting_card {
//       margin: 1.8rem 6rem 4rem 22rem;
//       background-color: white;
//       padding: 1.5rem;
//       border-radius: 0.5rem;
//       .pre_set_heading {
//         display: flex;
//         justify-content: center;
//         // margin-bottom: 4rem;
//       }
//       .product_url {
//         margin-top: 3rem;
//         .product_url_label {
//           font-size: 20px;
//           font-weight: 300;
//           padding-top: 0.5rem;
//         }
//         .url_input input {
//           width: 100%;
//           border: none;
//           background-color: rgb(250, 249, 249);

//           padding: 0.5rem;
//         }
//       }
//       .save_button {
//         margin-top: 4rem;
//         display: flex;
//         justify-content: center !important;
//         Button {
//           background-color: #ff9900;
//           border: none;
//           padding: 0.4rem 3.5rem;
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 600px) {
//   .main_setting_container {
//     height: 100vh;

//     h3 {
//       color: rgb(8, 8, 8);
//       margin-left: 10rem;
//       margin-top: 4.5rem;
//       font-weight: 600;
//       align-items: center;
//     }
//     .setting_card {
//       margin: 2.2rem 6rem 4rem 10rem;
//       background-color: white;
//       padding: 1.5rem;
//       width: 50%;
//       border-radius: 0.5rem;
//       .pre_set_heading {
//         display: flex;
//         text-align: center;
//       }
//       .product_url {
//         margin-top: 2.5rem;
//         .product_url_label {
//           font-size: 16px;
//           font-weight: 300;
//           padding-top: 0.5rem;
//           display: flex;
//           justify-content: center;
//         }
//         .url_input input {
//           width: 100%;
//           border: none;
//           background-color: rgb(250, 249, 249);

//           padding: 0.5rem;
//         }
//       }
//       .save_button {
//         margin-top: 4rem;
//         display: flex;
//         justify-content: center !important;
//         Button {
//           background-color: #ff9900;
//           border: none;
//           padding: 0.5rem 2.5rem;
//         }
//       }
//     }
//   }
// }
